
// 接口代理前缀
export const BASE_API = '/apis'

export const HTTP_SUCCESS_CODE = 0

// token key
export const TOKEN_KEY = 'fulcrum-ancare-token'
export const ROLE_KEY = 'fulcrum-ancare-role'
export const ROLE_CODE = 'fulcrum-ancare-role-code'
export const APP_CODE = 'applicationId'
export const DEFAULT_ID = 'defaultId'
export const FIRST_ROUTE = 'first-route'
export const IS_SIMPLE_PWD = 'isSimplePwd'

// 婚姻状况枚举
export const MARITAL_STATUS_ENUM = [{
  label: '未婚',
  value: 'WEIHUN'
}, {
  label: '已婚',
  value: 'YIHUN'
}, {
  label: '离异',
  value: 'LIYI'
}, {
  label: '再婚',
  value: 'ZAIHUN'
}]

// 学历
export const EDUCATION_ENUM = [{
  label: '初中及以下',
  value: 'junior'
}, {
  label: '高中',
  value: 'senior'
}, {
  label: '大专',
  value: 'junior_college'
}, {
  label: '本科',
  value: 'college'
}, {
  label: '硕士',
  value: 'master'
}, {
  label: '博士',
  value: 'doctor'
}]

// 客户级别
export const CLASSIFICATION_NUM = [{
  label: 'A：1个月内可能到诊所',
  value: 'A'
}, {
  label: 'B：3个月内可能到诊所',
  value: 'B'
}, {
  label: 'C：大于3个月内可能到诊所',
  value: 'C'
}, {
  label: 'D：没有意向',
  value: 'D'
}]

// 职业
export const JOB_ENUM = [{
  label: '计算机 / 通信 / 互联网',
  value: '计算机 / 通信 / 互联网'
}, {
  label: '生产 / 制造 / 工艺',
  value: '生产 / 制造 / 工艺'
}, {
  label: '医疗 / 护理 / 制药',
  value: '医疗 / 护理 / 制药'
}, {
  label: '金融 / 银行 / 投资 / 保险',
  value: '金融 / 银行 / 投资 / 保险'
}, {
  label: '商业 / 服务业 / 个体经营',
  value: '商业 / 服务业 / 个体经营'
}, {
  label: '文化 / 广告 / 传媒',
  value: '文化 / 广告 / 传媒'
}, {
  label: '娱乐 / 艺术 / 表演',
  value: '娱乐 / 艺术 / 表演'
}, {
  label: '律师 / 法务',
  value: '律师 / 法务'
}, {
  label: '教育 / 培训',
  value: '教育 / 培训'
}, {
  label: '公务员 / 行政 / 事业单位',
  value: '公务员 / 行政 / 事业单位'
}, {
  label: '模特',
  value: '模特'
}, {
  label: '空姐',
  value: '空姐'
}, {
  label: '学生',
  value: '学生'
}, {
  label: '其他',
  value: '其他'
}]

// 性别
export const GENDER_ENUM = [{
  label: '男',
  value: 1
}, {
  label: '女',
  value: 2
}]

// 菜单类型
export const MENU_TYPE = [{
  label: '目录',
  value: 'MENU'
}, {
  label: '链接',
  value: 'LINK'
}, {
  label: '按钮',
  value: 'BUTTON'
}]


// 菜单归属类型
export const MENU_BELONG_TYPE = [{
  label: '全部',
  value: 'ALL_MENU',
}, {
  label: '集团',
  value: 'PLATFORM_MENU'
}, {
  label: '诊所',
  value: 'CLINIC_MENU'
}]

// 字典角色类型
export const ROLE_TYPE = [{
  label: '集团',
  value: 'PLATFORM',
}, {
  label: '诊所',
  value: 'CLINIC'
}]

export const WORKBENCH_CONFIG = [{
  role: 'CLINIC_MASTER',
  upcomings: ['needReserve', 'needEvaluate', 'needAdvisory', 'needTreatment', 'needPay', 'needToShop', 'needFollow'],
  datas: [
    'customerNum', 'createdNum', 'evaluateNum', 'growOrderNum', 'advisorNum', 'treatmentNum', 'reservedNum', 'toShopNum',
    'doneNum', 'totalAmount', 'growOrderPercent', 'growOrderAmount'
  ]
}, {
  role: 'ASSISTANT',
  upcomings: ['needFollow', 'needReserve', 'needToShop'],
  datas: [
    'createdNum', 'reservedNum', 'toShopNum', 'doneNum', 'totalAmount',
  ]
}, {
  role: 'ADVISER',
  upcomings: ['needEvaluate', 'needPay', 'needReserve', 'needFollow'],
  datas: [
    'evaluateNum', 'growOrderNum', 'growOrderPercent', 'growOrderAmount', 'totalAmount',
  ]
}, {
  role: 'KEEPER',
  upcomings: ['needReserve', 'needToShop', 'needPay', 'needFollow'],
  datas: [
    'customerNum', 'reservedNum', 'toShopNum', 'doneNum', 'totalAmount',
  ]
}, {
  role: 'CONSULTANT',
  upcomings: ['needAdvisory', 'needReserve', 'needPay', 'needFollow'],
  datas: [
    'customerNum', 'advisorNum', 'reservedNum', 'toShopNum', 'doneNum', 'totalAmount',
  ]
}, {
  role: 'DOCTOR',
  upcomings: ['needTreatment', 'needReserve', 'needPay', 'needToShop'],
  datas: [
    'customerNum', 'treatmentNum', 'reservedNum', 'toShopNum', 'doneNum', 'totalAmount',
  ]
}]

// 产品服务类型
// export const PRODUCT_TYPE= [{
//   label: '首诊',
//   value: 'first_visit'
// }, {
//   label: '复诊',
//   value: 'subsequent_visit'
// }]

// 产品服务类型
export const PRODUCT_TYPE = [{
  label: '诊疗类服务',
  value: 'diagnostic_and_treatment_service'
}, {
  label: '非诊疗类服务',
  value: 'non_diagnostic_and_treatment_service'
}]

// 线上线下
export const ONLINE_TYPE = [{
  label: '线上',
  value: 'online'
}, {
  label: '线下',
  value: 'offline'
}]

// 服务类型 
export const SERVICE_TYPE = [{
  label: '量表',
  value: 'scale'
}, {
  label: '报告',
  value: 'report'
}, {
  label: '表单',
  value: 'form'
}, {
  label: '专科检测',
  value: 'evaluation_and_testing'
},]

//产品类型
export const PRODUCT_TYPE_ENUM = [{
  label: '服务',
  value: 'service'
}, {
  label: '药品',
  value: 'drug'
}]

// 产品分类类型-服务
export const PRODUCT_CATEGORY_TYPE_SERVICE = 'service_product';
// 产品类型-药品
export const PRODUCT_CATEGORY_TYPE_DRUG = 'drug_product';
// 产品类型-互联网药品
export const PRODUCT_CATEGORY_TYPE_HLW_DRUG = 'medicine';

// 执业类型枚举
export const PRACTICE_TYPE_ENUM = [{
  label: '医师',
  value: 'physician'
}, {
  label: '护师',
  value: 'nurse'
}, {
  label: '药师',
  value: 'pharmacist'
}, {
  label: '技师',
  value: 'technician'
}]

// 审核状态枚举Audit
export const AUDIT_STATUS_ENUM = [{
  label: '未审核',
  value: 'unchecked'
}, {
  label: '审核通过',
  value: 'pass'
}, {
  label: '审核失败',
  value: 'failure'
}]

// 职称枚举job title
export const JOB_TITLE_ENUM = [{
  label: '主任医生',
  value: 'chief_doctor'
}, {
  label: '副主任医生',
  value: 'deputy_chief_doctor'
}, {
  label: '主治医生',
  value: 'attending_doctor'
}]

// 医生患者性别枚举
export const DOCTORS_GENDER_ENUM = [{
  label: '男性',
  value: 'male'
}, {
  label: '女性',
  value: 'female'
}, {
  label: '未知',
  value: 'unknown'
}]

// 药品用法枚举usage
export const DRUG_USAGE_ENUM = [{
  label: '口服',
  value: 'oral'
}, {
  label: '注射',
  value: 'injection'
}]

// 药品订单状态
export const DRUG_ORDER_STATUS = [{
  label: '申请中',
  value: 'applying'
}, {
  label: '已拒绝',
  value: 'rejected'
}, {
  label: '取消申请',
  value: 'cancel_application'
}, {
  label: '待支付',
  value: 'to_pay'
}, {
  label: '已取消',
  value: 'cancelled'
}, {
  label: '已支付',
  value: 'paid'
}, {
  label: '已完成',
  value: 'success'
}, {
  label: '退款完成',
  value: 'refund_success'
}, {
  label: '退款中',
  value: 'pending_refund'
},]

// 退款订单状态
export const REFUND_ORDER_STATUS = [{
  label: '退款中',
  value: 'pending_refund'
}, {
  label: '退款完成',
  value: 'refund_success'
},]

// 退款订单类型
export const REFUND_ORDER_TYPE = [{
  label: '仅退款',
  value: 'only_refund'
}, {
  label: '退货退款',
  value: 'return_and_refund'
},]

// 服务订单订单状态
export const ADVISORY_ORDER_STATUS = [{
  label: '待支付',
  value: 'to_pay'
}, {
  label: '待支付后取消',
  value: 'to_pay_canceled'
}, {
  label: '待咨询',
  value: 'waiting_for_consulting'
}, {
  label: '咨询中',
  value: 'consulting'
}, {
  label: '已拒绝',
  value: 'rejected'
}, {
  label: '已支付后取消',
  value: 'paid_canceled'
}, {
  label: '已完成',
  value: 'success'
},]

// 订单来源
export const ORDER_SOURCE = [{
  label: 'H5页面',
  value: 'h5'
}, {
  label: 'web页面',
  value: 'web'
}]

// 物流状态枚举
export const LOGISTICS_STATUS = [{
  label: '---',
  value: 'un_start'
}, {
  label: '未发货',
  value: 'un_delivered'
}, {
  label: '已发货',
  value: 'delivered'
}]

// 处方状态枚举 prescription
export const PRESCRIPTION_STATUS = [{
  label: '未审方',
  value: 'un_checked'
}, {
  label: '已审方',
  value: 'checked'
}, {
  label: '已过期',
  value: 'expired'
}]

// 订单支付状态
export const ORDER_PAY_STATUS = [{
  label: '支付成功',
  value: 'success'
}, {
  label: '支付失败',
  value: 'fail'
}]

// 功能枚举Features
export const FEATURES_STATUS = [{
  label: '正常',
  value: 'normal'
}, {
  label: '异常',
  value: 'abnormal'
}, {
  label: '不详',
  value: 'unknown'
}]

// 过敏史Allergy
export const ALLERGY_STATUS = [{
  label: '有',
  value: 'have'
}, {
  label: '无',
  value: 'not_have'
}, {
  label: '不详',
  value: 'unknown'
}]

// 频次枚举frequency
export const FREQUENCY_ENUM = [{
  label: '每日一次',
  value: 'once_a_day'
}, {
  label: '每日两次',
  value: 'twice_a_day'
}, {
  label: '每日三次',
  value: 'three_times_a_day'
}, {
  label: '每日四次',
  value: 'four_times_a_day'
}, {
  label: '每晚一次',
  value: 'once_a_night'
}, {
  label: '隔日一次',
  value: 'every_other_day'
}, {
  label: '每周一次',
  value: 'once_a_week'
}, {
  label: '每周两次',
  value: 'twice_a_week'
}, {
  label: '每两周一次',
  value: 'every_two_weeks'
}, {
  label: '每月一次',
  value: 'once_a_month'
}]

// ,{
//   label: '每三月一次',
//   value: 'every_three_months'
// },{
//   label: '必要时',
//   value: 'when_necessary'
// },{
//   label: '紧急时',
//   value: 'in_an_emergency'
// }

// 提现方式
export const WITHDRAW_TYPE_MNUM = [{
  label: '微信',
  value: 'wechat'
}]

// 提现状态
export const WITHDRAW_STATUS_MNUM = [{
  label: '待审核',
  value: 'to_check'
}, {
  label: '待打款',
  value: 'to_remit'
}, {
  label: '已打款',
  value: 'remitted'
}]

// 处方笺类型
export const PRESCTIPTION_TYPE_ADULT = 'adult'  //成人处方笺
export const PRESCTIPTION_TYPE_CHILD = 'child'  //儿童处方笺

// womanNotes
export const WOMAN_NOTES_MNUM = [{
  label: '备孕',
  value: 'prepare_for_pregnancy'
}, {
  label: '妊娠',
  value: 'gravidity'
}, {
  label: '哺乳',
  value: 'lactation'
}]

// 药品 精二类型psychotropic_substances_of_category_2MedicineType
export const PSYCHOTROPIC_TYPE_CATEGORY = 'psychotropic_substances_of_category_2'  //精二类
export const PSYCHOTROPIC_TYPE_OTHER = 'psychotropic_substances_of_other'  //非精二类

export const MEDICINE_TYPE_MNUM = [{
  label: '非精二类',
  value: PSYCHOTROPIC_TYPE_OTHER
}, {
  label: '精二类',
  value: PSYCHOTROPIC_TYPE_CATEGORY
},]

export const CONSULTATION_TYPE_ONCE = 'once'
export const CONSULTATION_TYPE_COMBO = 'combo'
// 咨询类型consultationType combo
export const CONSULTATION_TYPE_MNUM = [{
  label: '单次咨询',
  value: CONSULTATION_TYPE_ONCE
}, {
  label: '套餐服务',
  value: CONSULTATION_TYPE_COMBO
}]

export const PRODUCT_TYPE_SERVICE = 'service'
export const PRODUCT_TYPE_CONSULTATION = 'consultation'

export const PRODUCT_TYPE_NEW = [{
  label: '服务',
  value: PRODUCT_TYPE_SERVICE
}, {
  label: '咨询',
  value: PRODUCT_TYPE_CONSULTATION
}]

// 物流状态 traces
export const TRACES_STATUS = [{
  label: '暂无轨迹信息',
  value: 'unstart'
}, {
  label: '已揽收',
  value: 'collect'
}, {
  label: '在途中',
  value: 'in_transit'
}, {
  label: '到达派件城市',
  value: 'arrive_at_the_dispatch_city'
}, {
  label: '派件中',
  value: 'in_the_delivery'
}, {
  label: '已放入快递柜或驿站',
  value: 'arrive_at_the_express_cabinet_or_courier_station'
}, {
  label: '签收',
  value: 'sign_for'
}, {
  label: '正常签收',
  value: 'normal_sign_for_it'
}, {
  label: '派件异常后最终签收',
  value: 'abnormal_sign_for_it'
}, {
  label: '代收签收',
  value: 'collection_sign_for'
}, {
  label: '快递柜或驿站签收',
  value: 'sign_in_the_express_cabinet_or_post_office'
}, {
  label: '问题件',
  value: 'problem_shipment'
}]


// 规则产品类型枚举：RULES_PRODUCT_TYPE
export const RULES_PRODUCT_TYPE_OBJ = {
  any: '任一',
  the_same: '同一'
}

export const UPGRADE_ORDER_TYPE_OBJ = {
  first_visit_upgrade_order: '首诊升单',
  subsequent_visit_upgrade_order: '复诊升单'
}

export const FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const APPLICATION_SYS = {
  '安肯云平台': 1,
  '安肯云诊所': 2,
  '互联网医院': 3,
  '安肯集团': 4,

}

export const APPLICATION_SYS_ARRAY = Object.keys(APPLICATION_SYS).map((key) => {
  return {
    label: key,
    value: APPLICATION_SYS[key]
  }
})

//渠道分类属性
export const CHANNEL_TYPE = {
  level_1_channel: "一级渠道分类",
  level_2_channel: "二级渠道分类",
  '一级渠道分类': 'level_1_channel',
  '二级渠道分类': 'level_2_channel'
}

//到店状态（未到店／首次到店／复诊到店）
export const ARRIVAl_STATUS = {
  // not_arrival:'未到店',
  // first_arrival:'首次到店',
  // subsequent_arrival:'复诊到店'
  //修改后的
  not_arrive: '未到店',
  first_arrive: '首次到店',
  again_arrive: '复诊到店'
}

export const ARRIVAl_STATUS_ARRAY = Object.keys(ARRIVAl_STATUS).map((key) => {
  return {
    label: ARRIVAl_STATUS[key],
    value: key
  }
})

// 诊疗状态Diagnosis
export const DIAGNOSIS_TYPE = [{
  label: '首诊',
  value: 'first_visit_customer'
}, {
  label: '复诊',
  value: 'subsequent_visit_customer'
}, {
  label: '无',
  value: 'normal_customer'
}
  // ,{
  //   label: '普通',
  //   value: 'normal_customer'
  // }
]

export const TASK_STATUS_TO_EXECUTE = 'to_execute';
export const TASK_STATUS_RUNNING = 'running';
export const TASK_STATUS_FINISHED = 'finished';
export const TASK_STATUS_REFUND = 'refund';
export const TASK_STATUS_UNSTART = 'unstart'
export const TASK_STATUS_CANCEL = 'cancel'

// 任务状态refund
export const TASK_STATUS_MAP = {
  [TASK_STATUS_TO_EXECUTE]: '待执行',
  [TASK_STATUS_RUNNING]: '执行中',
  [TASK_STATUS_FINISHED]: '已完成',
  [TASK_STATUS_REFUND]: '已退款',
  [TASK_STATUS_UNSTART]: '未开始',
  [TASK_STATUS_CANCEL]: '取消'
};

export const TASK_STATUSES = Object.keys(TASK_STATUS_MAP).map(key => ({ name: TASK_STATUS_MAP[key], value: key }));



// 待确认订单状态
export const ORDER_STATUS_TO_CONFIRM = 'to_confirm';

// 待支付订单状态
export const ORDER_STATUS_TO_PAY = 'to_pay';

// 已取消订单状态
export const ORDER_STATUS_CANCEL = 'cancel';

// 部分支付订单状态
export const ORDER_STATUS_PART_PAY = 'part_pay';

// 已支付订单状态
export const ORDER_STATUS_PAID = 'paid';

// 部分退款订单状态
export const ORDER_STATUS_PART_REFUND = 'part_refund';

// 退款中订单状态
export const ORDER_STATUS_REFUNDING = 'refunding';

// 已退款订单状态
export const ORDER_STATUS_REFUNDED = 'refunded';

// 已完成订单状态
export const ORDER_STATUS_SUCCESS = 'success';

// 订单状态map
export const ORDER_STATUS_MAP = {
  [ORDER_STATUS_TO_CONFIRM]: '待确认',
  [ORDER_STATUS_TO_PAY]: '待支付',
  [ORDER_STATUS_PAID]: '已支付',
  // [ORDER_STATUS_CANCEL]: '已取消',
  [ORDER_STATUS_PART_PAY]: '部分支付',
  // [ORDER_STATUS_PART_REFUND]: '部分退款',
  // [ORDER_STATUS_REFUNDING]: '退款中',
  // [ORDER_STATUS_REFUNDED]: '已退款',
  [ORDER_STATUS_SUCCESS]: '已完成',
};

// 待预约-执行状态
export const ORDER_EXECUTION_STATUS_TO_RESERVATION = 'to_reservation';

// 执行中-执行状态
export const ORDER_EXECUTION_STATUS_EXECUTION = 'execution';

// 部分完成-执行状态
export const ORDER_EXECUTION_STATUS_PART_SUCCESS = 'part_success';

// 已完成-执行状态
export const ORDER_EXECUTION_STATUS_SUCCESS = 'success';

// 执行状态map
export const ORDER_EXECUTION_STATUS_MAP = {
  [ORDER_EXECUTION_STATUS_TO_RESERVATION]: '待预约',
  [ORDER_EXECUTION_STATUS_EXECUTION]: '执行中',
  [ORDER_EXECUTION_STATUS_PART_SUCCESS]: '部分完成',
  [ORDER_EXECUTION_STATUS_SUCCESS]: '已完成'
};

// 订单类型 public enum OrderTypeEnum {
export const ORDER_TYPE_ENUM = [{
  label: '新签',
  value: 'first'
}, {
  label: '续费',
  value: 'renew'
}]

export const ORDER_SOURCE_ARRAY = [
  { label: '集团下单', value: 'platform' },
  { label: '诊所下单', value: 'clinic' },
]

//支付方式 新增收款
export const PAYMENT_TYPE_IN_COLLECTION = [{
  label: '线上支付',
  value: 'online_payment'
}, {
  label: '线下补录',
  value: 'offline_supplement'
},]


// 线上线下 支付方式 的显示
export const PAYMENT_METHOD_SHOW = {
  cash_pay: '现金支付',
  pos_pay: '线下POS机',
  meituan_coupon: '大众美团验券',
  koubei_coupon: '口碑验券',
  tmall_coupon: '天猫验券',
  wechat_mini_program: '微信小程序',
  medicare: '医保',
  pinduoduo_coupon: '拼多多验券',
  other_coupon: '其他验券',
  remote_payment: '远程支付',
  transfer_in_wechat: '微信转账',
  alipay_transfer: '支付宝转账',
  //线上支付方式
  WXZF: '微信支付',
  ZFBZF: '支付宝支付',
  UPSMPAY: '银联扫码',
  DCPAY: '数字货币'
};

export const PAYMENT_METHODS_SHOW = Object.keys(PAYMENT_METHOD_SHOW).map(key => ({ name: PAYMENT_METHOD_SHOW[key], value: key }));

//支付状态
export const PAYMENT_STATUS = {
  to_pay: "待支付",
  paying: "支付中",
  success: "支付成功",
  fail: "支付失败"

}
// 退款状态
export const REFUNT_STATUS = {
  to_audit: '待审核',
  success: '审核通过',
  fail: '审核不通过',
}

// 线上退款时候的显示   退款方式
export const REFUND_METHOD_SHOW = {
  cash_pay: '现金支付',
  online_pay: '在线支付',
  transfer_in_wechat: '微信转账',
  wechat_pay: '微信支付',
  alipay: '支付宝支付',
  bank_transfer: '银行转账',
  remote_payment: '远程支付',
  // wechat_transfer:'微信转账',
  alipay_transfer: '支付宝转账',
  WXZF: '微信支付',
  ZFBZF: '支付宝支付',
  UPSMPAY: '银联二维码',
  DCPAY: '数字货币',

}

export const PAYMENT_STATUS_ARRAY = Object.keys(PAYMENT_STATUS).map((key) => { return { label: PAYMENT_STATUS[key], value: key } })

//线上退款 or  线下退款
export const REFUND_TYPE = [{
  label: '线上退款',
  value: 'online_refund'
}, {
  label: '线下退款',
  value: 'offline_refund'
},]


export const MONTH = [{ label: '1月', value: '01' }, { label: '2月', value: '02' }, { label: '3月', value: '03' }, { label: '4月', value: '04' }, { label: '5月', value: '05' }, { label: '6月', value: '06' }, { label: '7月', value: '07' }, { label: '8月', value: '08' }, { label: '9月', value: '09' }, { label: '10月', value: '10' }, { label: '11月', value: '11' }, { label: '12月', value: '12' }]
export const QUARTER = [{ label: '第一季度', value: '一' }, { label: '第二季度', value: '二' }, { label: '第三季度', value: '三' }, { label: '第四季度', value: '四' },]
export const YEAR = [{ label: '上半年', value: 'firstHalf' }, { label: '下半年', value: 'lowerHalf' }, { label: '全年', value: 'allYear' }]

//正则  数字和字母组合大于6
export const REG = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,}$/

