import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/UserLayout'),
          LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/loginPage/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../loginPage'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                .default,
            })
          : require('../loginPage').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BasicLayout'),
          LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    name: '首页',
    authority: ['admin', 'assiant', 'guidance'],
    routes: [
      {
        path: '/',
        redirect: '/enterprise-manager/list',
        exact: true,
      },
      {
        path: '/zjghpt',
        name: 'zjghpt',
        text: '浙江挂号平台',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/zjghpt/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../zjghpt'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                .default,
            })
          : require('../zjghpt').default,
        exact: true,
      },
      {
        path: '/miniprogram',
        name: 'miniprogram',
        text: '小程序管理',
        icon: 'setting',
        routes: [
          {
            path: '/miniprogram/reserveList',
            name: 'miniprogram-reserveList',
            text: '预约管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/miniprogram/reserveList/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../miniprogram/reserveList'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../miniprogram/reserveList').default,
            exact: true,
          },
          {
            path: '/miniprogram/clinicList',
            name: 'miniprogram-clinicList',
            text: '诊所展示',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/miniprogram/clinicList/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../miniprogram/clinicList'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../miniprogram/clinicList').default,
            exact: true,
          },
        ],
      },
      {
        path: '/group',
        name: 'group',
        text: '集团管理',
        icon: 'global',
        routes: [
          {
            path: '/group/organization',
            name: 'group-organization',
            text: '组织机构',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/group/organization/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../group/organization'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../group/organization').default,
            exact: true,
          },
          {
            path: '/group/member',
            name: 'group-member',
            text: '员工列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/group/members/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../group/members'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../group/members').default,
            exact: true,
          },
          {
            path: '/group/settleConsultant',
            name: 'group-settleConsultant',
            text: '咨询师入驻',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/group/settleConsultant/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../group/settleConsultant'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../group/settleConsultant').default,
            exact: true,
          },
        ],
      },
      {
        path: '/enterprise-manager',
        name: 'enterprise-manager',
        text: '诊所管理',
        icon: 'bank',
        routes: [
          {
            path: '/enterprise-manager/access',
            name: 'access',
            text: '诊所接入',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/EnterpriseManager/Access/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../EnterpriseManager/Access'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../EnterpriseManager/Access').default,
            exact: true,
          },
          {
            path: '/enterprise-manager/list',
            name: 'enterprise-list',
            text: '诊所列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/EnterpriseManager/List/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../EnterpriseManager/List'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../EnterpriseManager/List').default,
            exact: true,
          },
        ],
      },
      {
        path: '/doctors',
        name: 'doctors',
        text: '医生管理',
        icon: 'user',
        routes: [
          {
            path: '/doctors/list',
            name: 'doctors-list',
            text: '医生管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/doctors/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../doctors'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../doctors').default,
            exact: true,
          },
          {
            path: '/doctors/medical-aid',
            name: 'doctors-medical-aid',
            text: '医助管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/doctors/medicalAid/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/doctors/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../doctors/medicalAid'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../doctors/medicalAid').default,
            exact: true,
          },
        ],
      },
      {
        path: '/pms',
        name: 'pms',
        text: '产品管理',
        icon: 'bank',
        routes: [
          {
            path: '/pms/list',
            name: 'pms-list',
            text: '产品目录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/pms/List/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../pms/List'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../pms/List').default,
            exact: true,
          },
          {
            path: '/pms/add',
            name: 'pms-add',
            text: '添加产品',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/pms/Add/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../pms/Add'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../pms/Add').default,
            exact: true,
          },
          {
            path: '/pms/edit',
            hideInMenu: true,
            name: 'pms-edit',
            text: '编辑产品',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/pms/Edit/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../pms/Edit'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../pms/Edit').default,
            exact: true,
          },
        ],
      },
      {
        path: '/drug',
        name: 'drug',
        text: '药品管理',
        icon: 'bank',
        routes: [
          {
            path: '/drug/list',
            name: 'drug-list',
            text: '药品管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drug/list/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../drug/list'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../drug/list').default,
            exact: true,
          },
          {
            path: '/drug/classification',
            name: 'drug-classification',
            text: '药品分类',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drug/classification/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../drug/classification'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../drug/classification').default,
            exact: true,
          },
          {
            path: '/drug/setDoctorDrug',
            name: 'doctors-setDoctorDrug',
            text: '医生药品目录',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drug/setDoctorDrug/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../drug/setDoctorDrug'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../drug/setDoctorDrug').default,
            exact: true,
          },
        ],
      },
      {
        path: '/order',
        name: 'order',
        text: '订单管理',
        icon: 'profile',
        routes: [
          {
            path: '/order/drug',
            name: 'drug-order-list',
            text: '药品订单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drugOrder/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../drugOrder'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../drugOrder').default,
            exact: true,
          },
          {
            path: '/order/advisory',
            name: 'advisory-order-list',
            text: '咨询订单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drugOrder/advisoryOrder/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/drugOrder/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../drugOrder/advisoryOrder'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../drugOrder/advisoryOrder').default,
            exact: true,
          },
          {
            path: '/order/refund',
            name: 'refund-order-list',
            text: '退款订单',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/refund/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../refund'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../refund').default,
            exact: true,
          },
        ],
      },
      {
        path: '/finance',
        name: 'finance',
        text: '财务管理',
        icon: 'dollar',
        routes: [
          {
            path: '/finance/withdraw',
            name: 'finance-withdraw',
            text: '提现管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/finance/withdraw/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../finance/withdraw'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../finance/withdraw').default,
            exact: true,
          },
          {
            path: '/finance/commission',
            name: 'finance-commission',
            text: '佣金管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/finance/commission/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../finance/commission'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../finance/commission').default,
            exact: true,
          },
          {
            path: '/finance/freight',
            name: 'finance-freight',
            text: '运费管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/finance/freight/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../finance/freight'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../finance/freight').default,
            exact: true,
          },
        ],
      },
      {
        path: '/system',
        name: 'system',
        text: '系统设置',
        icon: 'setting',
        routes: [
          {
            path: '/system/dictionary',
            name: 'dictionary',
            text: '字典管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/dictionary/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/dictionary'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/dictionary').default,
            exact: true,
          },
          {
            path: '/system/dictionary/term',
            name: 'term',
            text: '字典管理',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/dictionary/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/dictionary/term.js'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/dictionary/term.js').default,
            exact: true,
          },
          {
            path: '/system/channel',
            name: 'channel',
            text: '渠道管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/channel/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/channel'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/channel').default,
            exact: true,
          },
          {
            path: '/system/customerStatus',
            name: 'customerStatus',
            text: '客户状态管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/customerStatus/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/customerStatus'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/customerStatus').default,
            exact: true,
          },
          {
            path: '/system/channelType',
            name: 'channelType',
            text: '渠道分类管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/channelType/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/channelType'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/channelType').default,
            exact: true,
          },
          {
            path: '/system/dailyReportClass',
            name: 'dailyReportClass',
            text: '日报类别管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/system/dailyReportClass/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../system/dailyReportClass'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../system/dailyReportClass').default,
            exact: true,
          },
        ],
      },
      {
        path: '/contentList',
        name: 'contentList',
        text: '内容管理',
        icon: 'setting',
        authority: ['admin', 'assiant', 'guidance'],
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/contentList/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../contentList'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                .default,
            })
          : require('../contentList').default,
        exact: true,
      },
      {
        path: '/contentConsultantSet',
        name: 'contentConsultantSet',
        text: '编写入驻协议',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/contentConsultantSet/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../contentConsultantSet'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                .default,
            })
          : require('../contentConsultantSet').default,
        exact: true,
      },
      {
        path: '/upOrderMananger',
        name: 'upOrderMananger',
        text: '升单管理',
        icon: 'setting',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/upOrderMananger/product',
            name: 'product',
            text: '升单产品',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/upOrderMananger/product/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../upOrderMananger/product'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../upOrderMananger/product').default,
            exact: true,
          },
          {
            path: '/upOrderMananger/upOrderClassify',
            name: 'upOrderClassify',
            text: '升单分类',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/upOrderMananger/upOrderClassify/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../upOrderMananger/upOrderClassify'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../upOrderMananger/upOrderClassify').default,
            exact: true,
          },
          {
            path: '/upOrderMananger/upRule',
            name: 'upRule',
            text: '升单规则',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/upOrderMananger/upRule/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../upOrderMananger/upRule'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../upOrderMananger/upRule').default,
            exact: true,
          },
          {
            path: '/upOrderMananger/clinicRule',
            name: 'clinicRule',
            text: '诊所规则',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/upOrderMananger/clinicRule/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../upOrderMananger/clinicRule'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../upOrderMananger/clinicRule').default,
            exact: true,
          },
          {
            path: '/upOrderMananger/clinicRuleDetail',
            name: 'clinicRuleDetail',
            text: '诊所详情',
            hideInMenu: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/upOrderMananger/clinicRuleDetail/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../upOrderMananger/clinicRuleDetail'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../upOrderMananger/clinicRuleDetail').default,
            exact: true,
          },
        ],
      },
      {
        path: '/purpose',
        name: 'purpose',
        text: '目标管理',
        icon: 'line-chart',
        routes: [
          {
            path: '/purpose/setting',
            name: 'purpose-setting',
            text: '目标设置',
            authority: ['admin'],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/purpose/setting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../purpose/setting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../purpose/setting').default,
            exact: true,
          },
          {
            path: '/purpose/info',
            name: 'purpose-info',
            text: '目标进度',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/purpose/info/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../purpose/info'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../purpose/info').default,
            exact: true,
          },
        ],
      },
      {
        path: '/statistic',
        name: 'statistic',
        text: '数据统计',
        icon: 'line-chart',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/statistic/operation',
            name: 'statistic-operation',
            text: '运营分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/operation/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/operation'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/operation').default,
            exact: true,
          },
          {
            path: '/statistic/dailyReport',
            name: 'daily-Report',
            text: '日报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/dailyReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/dailyReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/dailyReport').default,
            exact: true,
          },
          {
            path: '/statistic/report',
            name: 'statistic-report',
            text: '周月报表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/report/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/report'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/report').default,
            exact: true,
          },
          {
            path: '/statistic/statisticChart',
            name: 'statistic-statisticChart',
            text: '报表统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/statisticChart/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/statisticChart'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/statisticChart').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/statistic/original',
            name: 'statistic-original',
            text: '原始数据统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/original/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/original'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/original').default,
            exact: true,
          },
          {
            path: '/statistic/monthReport',
            name: 'statistic-monthReport',
            text: '月报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/monthReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/monthReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/monthReport').default,
            exact: true,
          },
          {
            path: '/statistic/weekReport',
            name: 'statistic-weekReport',
            text: '周报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/weekReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/weekReport'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/weekReport').default,
            exact: true,
          },
          {
            path: '/statistic/fuck',
            name: 'statistic-weekReport',
            text: '周报统计',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/statistic/weekReport/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../statistic/weekReport/index1.js'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../statistic/weekReport/index1.js').default,
            exact: true,
          },
        ],
      },
      {
        path: '/publishNotice',
        name: 'systemManager-publishNotice',
        text: '系统公告',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/publishNotice/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../systemManager/publishNotice'),
              LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                .default,
            })
          : require('../systemManager/publishNotice').default,
        exact: true,
      },
      {
        path: '/systemManager',
        name: 'systemManager',
        text: '系统管理',
        icon: 'setting',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/systemManager/accountManager',
            name: 'systemManager-accountManager',
            text: '账号管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/accountManager/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/accountManager'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/accountManager').default,
            exact: true,
          },
          {
            path: '/systemManager/jobName',
            name: 'systemManager-jobName',
            text: '岗位管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/jobName/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/jobName'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/jobName').default,
            exact: true,
          },
          {
            path: '/systemManager/menuManager',
            name: 'systemManager-menuManager',
            text: '菜单管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/menuManager/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/menuManager'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/menuManager').default,
            exact: true,
          },
          {
            path: '/systemManager/systemRole',
            name: 'systemManager-systemRole',
            text: '系统角色',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/systemRole/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/systemRole'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/systemRole').default,
            exact: true,
          },
          {
            path: '/systemManager/addAccount',
            name: 'systemManager-addAccount',
            text: '新增账号',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/addAccount/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/addAccount'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/addAccount').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/systemManager/accountDetail',
            name: 'systemManager-accountDetail',
            text: '账号详情',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/accountDetail/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/accountDetail'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/accountDetail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/systemManager/dataTransfer',
            name: 'systemManager-dataTransfer',
            text: '数据转移',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/systemManager/dataTransfer/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../systemManager/dataTransfer'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../systemManager/dataTransfer').default,
            hideInMenu: true,
            exact: true,
          },
        ],
      },
      {
        path: '/achievement',
        name: 'achievement',
        text: '绩效管理',
        icon: 'setting',
        authority: ['admin', 'assiant', 'guidance'],
        routes: [
          {
            path: '/achievement/typeSetting',
            name: 'achievement-accountManager',
            text: '绩效归类设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/achievement/typeSetting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../achievement/typeSetting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../achievement/typeSetting').default,
            exact: true,
          },
          {
            path: '/achievement/ruleSetting',
            name: 'achievement-ruleSetting',
            text: '绩效规则设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/achievement/ruleSetting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../achievement/ruleSetting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../achievement/ruleSetting').default,
            exact: true,
          },
          {
            path: '/achievement/salaryAccounting',
            name: 'achievement-salaryAccounting',
            text: '工资绩效核算',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/lib/jenkins/workspace/ancare-platform-prev/src/pages/achievement/salaryAccounting/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../achievement/salaryAccounting'),
                  LoadingComponent: require('/var/lib/jenkins/workspace/ancare-platform-prev/src/components/PageLoading/index')
                    .default,
                })
              : require('../achievement/salaryAccounting').default,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
